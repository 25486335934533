import React from "react"
import "../global.css"

import { Layout, Header, Testimonial } from "../components"

export default () => {
  return (
    <Layout>
      <Header />
      <div className="relative pt-6 pb-12 sm:pb-16 md:pb-20 lg:pb-28 xl:pb-32">
        <main className="mt-10 mx-auto max-w-screen-xl px-4 sm:mt-12 sm:px-6 md:mt-16 lg:mt-20 xl:mt-28">
          <div className="md:grid md:grid-cols-7 md:gap-4">
            <div className="md:row-start-1 md:col-start-2 md:col-end-5 col-span-3">
              <h2 className="text-4xl font-extrabold text-gray-700 sm:text-2xl sm:leading-none md:text-3xl">
                Welcome to Big's Axe Throwing
              </h2>
              <p className="prose mt-3 max-w-md text-base text-gray-500 sm:text-sm md:mt-5 md:text-md md:max-w-3xl">
                We are Dalton’s first indoor axe throwing venue. Big's Axe
                Throwing was established in 2019. We cater to all walks of life.
                If you want to learn how to throw or sharpen your throwing
                skills Big’s Axe is the placed to be. Our rustic scenery helps
                to give an experience as a real axe thrower. We provide the
                targets, axes, and beer- all we need is you!
              </p>
              <p className="prose mt-3 max-w-md text-base text-gray-500 sm:text-sm md:mt-5 md:text-md md:max-w-3xl">
                Great for special occasions, a night in town, corporate event
                parties, team building exercises, etc. We promise you will have
                an axecellent time. Bring your friends and family for an
                activity that’s out of the ordinary and just have a kick axe
                time! Throwing for competition or throwing for fun, we hope you
                can make it back to show off your skills.
              </p>
            </div>
            <div className="md:row-start-1 md:col-start-5 col-span-2">
              <h2 className="text-4xl font-extrabold text-gray-700 sm:text-2xl sm:leading-none md:text-3xl mb-4">
                Hours of Operation
              </h2>
              <div className="prose text-sm">
                <p>Monday: Closed</p>
                <p>Tuesday-Thursday: 4:30pm-8pm</p>
                <p>Friday: 5pm-10pm</p>
                <p>Saturday: 12pm-10pm</p>
              </div>
              <div>
                <p className="text-xs italic">
                  We encourage everyone to arrive 5 to 10 minutes early before
                  reservation time.
                </p>
              </div>
            </div>
          </div>
        </main>
      </div>
      <Testimonial />
    </Layout>
  )
}
